body {
  margin: 0;
  padding: 0;
  font-family: sans-serif, Exo;
}

body.pushable {
  background-color: white !important;
}

.bgb {
  background-color: rgb(42, 27, 112);
}

#main-menu,
.ui.blue.inverted.menu {
  border-radius: 0;
}

#main-menu .item {
  font-weight: bold;
}

img.fade {
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  transition: opacity 1.5s ease-in-out;
  opacity: 0;
  transform: translateX(-50%);
}

img.show {
  opacity: 1;
}

.marquee {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.m1 {
  animation: marquee2 15s linear infinite;
  transform: translate3d(0, 0, 0);
  padding-left: 20px;
}

.m1:hover {
  animation-play-state: paused;
}

.m1 img {
  border-radius: 500rem;
  margin-right: 20px;
}

@keyframes marquee2 {
  100% {
    transform: translateX(-49.6%);
  }
}

.bgg {
  background-color: #f2f3f5;
}

.ui.vertical.menu .item > .header:not(.ui) {
  margin: 0;
}

hr {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-left: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.tdlt {
  text-decoration: line-through;
}

.player {
  width: 130px;
  height: 130px;
  border-radius: 500rem;
}

.player:after {
  background-color: red;
}

#root table thead tr th {
  background-color: #2185d0;
  color: white;
}

#root table tbody tr:hover {
  background-color: Lavender;
}

table.ui.striped.table tbody tr:nth-child(even) {
  background-color: #ffffd8;
}

#calendar.mobile td {
  padding: 2px;
}

#stats.mobile td,
#stats.mobile th,
#history.mobile td,
#history.mobile th,
#standing.mobile td,
#standing.mobile th,
#gstanding.mobile td,
#gstanding.mobile th,
#gstanding1.mobile td,
#gstanding1.mobile th,
#gstanding2.mobile td,
#gstanding2.mobile th,
#gstanding3.mobile td,
#gstanding3.mobile th,
#gstanding4.mobile td,
#gstanding4.mobile th,
#gstanding5.mobile td,
#gstanding5.mobile th,
#gstanding6.mobile td,
#gstanding6.mobile th,
#gstanding7.mobile td,
#gstanding7.mobile th,
#gstanding8.mobile td,
#gstanding8.mobile th {
  padding-left: 2px;
  padding-right: 0;
}

.ui.selection.dropdown {
  min-width: 1em;
}

.ui.selection.dropdown .menu {
  max-height: none;
}

.ui.grid {
  font-size: 12px;
  border-radius: 0.28571429rem;
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
}

.ui.grid > .row {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* border-bottom: black 1px solid; */
}

.ui.grid > .row > .column {
  /* border-left: black 1px solid;
  border-right: black 1px solid; */
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 0;
}

.thead {
  background-color: #2185d0;
  color: white;
}

div.ui.striped.grid div.tbody:nth-child(odd) {
  background-color: #ffffd8;
}

.fixdd div.visible.menu.transition {
  height: 300px;
}

img.fitHeight {
  display: block;
  width: auto;
  height: 500px;
}

td.result-select .ui.selection.dropdown {
  width: 60px;
}

td.result-select .visible.menu.transition {
  height: 400px;
}

.news {
  font-size: larger;
  font-weight: bolder;
  margin-bottom: 8px;
}

.wsn {
  white-space: nowrap;
}

#calendar td {
  vertical-align: top;
  padding: 1px;
}
